import React from "react"
// import {Link} from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import LeadForm from "../components/leadform";
// import MobileLeadingForm from "../components/mobileleadform";
import Blog from "../components/landingblog";
import {graphql, useStaticQuery} from "gatsby";
import {BLOCKS} from "@contentful/rich-text-types";
import Img from "gatsby-image";
import {documentToReactComponents} from "@contentful/rich-text-react-renderer";

const CaseStudy = (props) => {
    const data = useStaticQuery(graphql`
        query {
          allContentfulCaseStudy {
            edges {
              node {
                body {
                  body
                  id
                }
                id
                intro {
                  intro
                }
                title
                isHighlighted
                cover {
                  localFile {
                    url
                    childImageSharp {
                      fluid {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }

        }
    `);


    const options = {
        renderNode: {
            // eslint-disable-next-line react/display-name
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                const image = node.data.target.fields.file['en-US']
                const width = image.details.image.width
                return <Img style={{marginBottom: '28px'}} width={image.details.image.width} fluid={{
                    aspectRatio: width / image.details.image.height,
                    src: image.url + '?w=630&q=80',
                    srcSet: `
                      ${image.url}?w=${width / 4}&&q=80 ${width / 4}w,
                      ${image.url}?w=${width / 2}&&q=80 ${width / 2}w,
                      ${image.url}?w=${width}&&q=80 ${width}w,
                      ${image.url}?w=${width * 1.5}&&q=80 ${width * 1.5}w,
                      ${image.url}?w=1000&&q=80 1000w,
                     `,
                    sizes: '(max-width: 630px) 100vw, 630px'
                }}/>
            }
        }
    }

    const caseStudy = data.allContentfulCaseStudy.edges;
    // console.log(props.pageContext)
    const currentCaseStudy = caseStudy.filter(({node: a}) => a.id === props.pageContext.caseStudy.id)[0].node
    // currentArticle['type'] = 'article';

    return (
        <Layout>
            <SEO title="Case study" description={`Web application development and business digital transformation blog. A dive on best practices, web security, application performance and next gen digital experiences.`}/>
            <div className={'relative'}><div className={'hidden xxl:block purple-rotated-div'}/></div>
            <div className={'relative'}><div className={'hidden xxl:block gray-rotated-div'}/></div>
            {/*<div className={'hidden lg:block'}>*/}
            {/*    <LeadForm/>*/}
            {/*</div>*/}
            {/*<div className={'block lg:hidden'}>*/}
            {/*    <MobileLeadingForm/>*/}
            {/*</div>*/}
            <div className={'container mx-auto mb-10'}>
                <div className={'flex '}>
                    <div className="container mx-auto px-4">
                        <h1 className=" max-w-3xl mx-auto text-3xl text-purple-800  font-bold tracking-wide leading-snug mb-6 mt-4 lg:mb-10 lg:mt-16">{currentCaseStudy.title}</h1>
                        <Img fluid={currentCaseStudy.cover.localFile.childImageSharp.fluid} className="max-w-3xl mb-8 mx-auto "/>
                        <div className="mx-auto container text-navy-700">
                            <div className="article mx-auto max-w-3xl leading-relaxed tracking-wide">{documentToReactComponents(JSON.parse(currentCaseStudy.body.body), options)}</div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className={'hidden lg:block'}>*/}
            {/*    <LeadForm/>*/}
            {/*</div>*/}
            {/*<div className={'block lg:hidden'}>*/}
            {/*    <MobileLeadingForm/>*/}
            {/*</div>*/}
            <div className={''}>
                <Blog/>
            </div>
        </Layout>
    )
}

export default CaseStudy